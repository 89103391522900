import React from "react"

const Button = ({
  children,
  variant = "primary",
  type = "button",
  className,
}) => {
  const btnClassNames =
    variant === "secondary" ? "btn btn-secondary" : "btn btn-primary"
  return (
    <button className={`${btnClassNames} ${className}`} type={type}>
      {children}
    </button>
  )
}

export default Button
