import React from "react"

import { link } from "./Footer.module.css"

const Footer = ({ email, phone, address }) => {
  const contactInfo = [
    {
      text: phone,
      type: "tel",
    },
    {
      text: email,
      type: "email",
    },
  ]
  return (
    <footer className="bg-secondary p-8 text-gray-50 footer">
      <div className="container flex flex-col md:flex-row justify-around">
        <div>
          <div className="text-lg text-center">
            Griguol © {new Date().getFullYear()}
          </div>
        </div>
        <div>
          <div className="text-lg mb-3 text-center">Contacto</div>
          <ul className="flex flex-col items-center">
            {contactInfo.map(contact => (
              <li key={contact.text} className="text-xs pb-2">
                {contact.type === "email" ? (
                  <a
                    id="email"
                    className={link}
                    href={`mailto:${contact.text}`}
                  >
                    <span className="icon-envelope mr-1" aria-hidden="true" />
                    {contact.text}
                  </a>
                ) : (
                  <a id="email" className={link} href={`tel:${contact.text}`}>
                    <span
                      className="icon-phone-square mr-1"
                      aria-hidden="true"
                    />
                    {contact.text}
                  </a>
                )}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <div className="text-lg mb-3 text-center">Dirección</div>
          <ul className="flex flex-col items-center">
            <li className="text-xs">
              <address className="not-italic">
                <span className="icon-home mr-1" aria-hidden="true" />
                {address}
              </address>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}

export default Footer
